import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { SketchPicker } from "react-color";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";

const AddHobby = ({
  visible,
  onClose: onCloseModel,
  onSuccessClose,
  isEdit,
  itemToEdit,
  categories,
  hobby,
  setHobby,
}) => {
  const onChangeInput = (e) => {
    setHobby({
      ...hobby,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!hobby?.color_code) {
      toast.error("Color Code is required!");
      return;
    }
    const body = {
      name: hobby.name,
      category_id: hobby.category,
      color_code: hobby.color_code.hex,
    };

    let data = await (isEdit
      ? axiosInstance.put("/hobby/" + itemToEdit, body)
      : axiosInstance.post("/hobby", body));
    if (data?.status == 200) {
      // Empty State
      setHobby({});
      onSuccessClose();
      toast.success("Hobby Created Successful!");
    } else {
      toast.error(data?.message);
    }
  };

  const onClose = () => {
    setHobby({});
    onCloseModel();
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-[9999] " onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 " />
        </Transition.Child>

        <form onSubmit={submitForm} className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-medium leading-6 text-gray-900 mt-2 text-center"
                >
                  {isEdit ? "Edit" : "Add"} Hobbies
                </Dialog.Title>
                <div className="mt-4 flex-block items-center justify-center">
                  <div className="grid grid-cols-2 gap-4 ">
                    <div>
                      <label
                        class="block text-gray-700 text-sm font-medium mb-3"
                        for="email"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={hobby?.name}
                        onChange={onChangeInput}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Name"
                        required
                      ></input>
                    </div>
                    <div>
                      <label
                        for="countries"
                        class="block mb-3 text-sm font-medium text-gray-900"
                      >
                        Category
                      </label>
                      <select
                        id="countries"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        name="category"
                        value={hobby?.category}
                        onChange={onChangeInput}
                        required
                      >
                        <option selected value="">
                          Choose Category
                        </option>
                        {categories?.map((_) => (
                          <option value={_?._id}>{_?.name}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <div>
                        <label
                          class="block text-gray-700 text-sm font-medium mb-3"
                          for="color-code"
                        >
                          Color Code
                        </label>
                        <SketchPicker
                          color={hobby.color_code}
                          onChange={(color) => {
                            setHobby({
                              ...hobby,
                              color_code: color,
                            });
                          }}
                        />
                        {/* <input
                            class="mb-2 w-full max-w-sm shadow appearance-none border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="color-code"
                            type="text"
                            placeholder="Username"
                          /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex justify-between">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-11 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onClose}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-7 rounded"
                  >
                    Sumbit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition>
  );
};

export { AddHobby };
