import React from "react";
import {
  BrowserRouter,
  Routes as RoutesMain,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";
import { DashboardLayout, AuthLayout } from "../layouts";
import { HomePage } from "../screens/HomePage";
import Routes from "./routes";
import LandingPage from "../screens/LandingPage/index";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import TermsAndConditions from "../screens/TermsAndConditions";

const Navigation = () => {
  const { isAuthenticated } = useAuth();
  return (
    <BrowserRouter>
      <RoutesMain>
        <Route path="/" exact element={<LandingPage />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route
          path="/terms-and-conditions"
          exact
          element={<TermsAndConditions />}
        />
        {Routes?.map(({ navigate, path, component, isPrivate = false }) => {
          if (navigate) {
            return <Route path={path} element={<Navigate to={navigate} />} />;
          }
          return isPrivate ? (
            <Route
              path={path}
              element={
                <PrivateRoute
                  component={component}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          ) : (
            <Route
              path={path}
              element={
                <PublicRoute
                  component={component}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          );
        })}
      </RoutesMain>
    </BrowserRouter>
  );
};

const PublicRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  if (!isAuthenticated) {
    return (
      <AuthLayout>
        <Component />
      </AuthLayout>
    );
  } else {
    return <Navigate to="/admin/dashboard" />;
  }
};

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  if (isAuthenticated) {
    return (
      <DashboardLayout>
        <Component />
      </DashboardLayout>
    );
  } else {
    return <Navigate to="/admin/login" />;
  }
};

export default Navigation;
