import React from "react";
import AuthProvider from "./contexts/AuthProvider";
import Navigation from "./navigation/Navigation.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StoreProvider from "./contexts/StoreProvider";

const App = () => {
    return (
        <AuthProvider>
            <StoreProvider>
                <Navigation />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </StoreProvider>
        </AuthProvider>
    );
};

export default App;
