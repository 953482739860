import React from "react";
import navIcon from "../../assets/TermsAndConditions/Nav-icon.svg";
import { Link } from "react-router-dom";
const PrivacyHeader = () => {
  return (
    <>
      {" "}
      <nav className="flex justify-between md:mx-[70px] md:py-[0px] py-[20px] sticky">
        <div className="left-nav flex items-center justify-between">
          <Link to="/">
            <img src={navIcon} />
          </Link>
          <p className="drop-shadow-sm font-extrabold text-[#F95A2C] md:text-[16px] text-[10px] !text_shadow">
            Presence - Spiritual Dating
          </p>
        </div>
        <div className="right-nav flex items-center justify-between">
          <ul className="flex justify-between">
            <a href="/">
              {" "}
              {/* <li className="md:px-[30px] px-[10px] md:text-[16px] text-[10px]">
                FAQ
              </li> */}
            </a>
            <Link to="/terms-and-conditions">
              <li className="md:px-[30px] px-[10px] md:text-[16px] text-[10px]">
                Terms & Conditions
              </li>
            </Link>
            <Link to="/privacy-policy">
              <li className="md:px-[30px] px-[10px] md:text-[16px] text-[10px]">
                Privacy Policy
              </li>
            </Link>
          </ul>
        </div>
      </nav>
      <section className="sticky hero-section bg-gradient-to-r from-yellow-400 to-red-600 flex flex-col justify-center items-center">
        <h1 className="font-bold text-[30px] text-white pt-[70px] pb-[20px]">
          Privacy Notice
        </h1>
        <h1 className="text-white pb-[70px]">Last updated November 11, 2022</h1>
      </section>
    </>
  );
};

export default PrivacyHeader;
