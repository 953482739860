import React from "react";
// import GooglePlay from "../../assets/Terms-conditions/Google-play.svg";
// import AppStore from "../../assets/Terms-conditions/App-store.svg";
// import Facebook from "../../assets/Terms-conditions/facebook.svg";
// import LinkdIn from "../../assets/Terms-conditions/linkdin.svg";
// import Instagram from "../../assets/Terms-conditions/Instagram.svg";
// import Twitter from "../../assets/Terms-conditions/twiiter.svg";
// import Location from "../../assets/Terms-conditions/location.svg";
// import Phone from "../../assets/Terms-conditions/phone.svg";
import GooglePlay from "../../assets/TermsAndConditions/Google-play.svg";
import Location from "../../assets/TermsAndConditions/location.svg";
import AppStore from "../../assets/TermsAndConditions/App-store.svg";
import Facebook from "../../assets/TermsAndConditions/facebook.svg";
import LinkdIn from "../../assets/TermsAndConditions/linkdin.svg";
import Instagram from "../../assets/TermsAndConditions/Instagram.svg";
import Twitter from "../../assets/TermsAndConditions/twiiter.svg";
import Phone from "../../assets/TermsAndConditions/phone.svg";
import { Link } from "react-router-dom";
const PrivacyFooter = () => {
  return (
    <div>
      <footer className="px-[10px] gap-2 md:px-[0px] bg-gradient-to-r from-yellow-400 to-red-600 md:rounded-t-[50px] rounded-t-[30px] grid md:grid-cols-10 grid-cols-2 p">
        <div className="md:col-span-1 md:block hidden"></div>
        <div className="left-bottom  md:pb-[105px] pb-[25px] md:pt-[50px] pt-[35px] md:col-span-3 col-span-2">
          <h1 className="font-bold md:text-[18px] text-[15px]">
            Download and Signup Now!
          </h1>
          <p className="font-normal  pb-[10px] pt-[13px] md:text-[16px] text-[13px]">
            Book time with your favorite person and schedule performances of
            your most desired person with just a few clicks.
          </p>
          <div className="flex md:justify-normal justify-center">
            <img
              src={GooglePlay}
              alt=""
              className="md:w-[150px] w-[85px] pr-[2px]"
            />
            <img src={AppStore} alt="" className="md:w-[150px] w-[85px]" />
          </div>
          <div className="flex md:py-[28px] py-[16px] md:justify-normal justify-center">
            <img
              src={Facebook}
              alt=""
              className="md:pr-[16px] md:pr-[14px] pr-[9px] md:w-[50px] w-[40px]"
            />
            <img
              src={LinkdIn}
              alt=""
              className="md:pr-[16px] md:pr-[14px] pr-[9px] md:w-[50px] w-[40px]"
            />
            <img
              src={Twitter}
              alt=""
              className="md:pr-[16px] md:pr-[14px] pr-[9px] md:w-[50px] w-[40px]"
            />
            <img
              src={Instagram}
              alt=""
              className="md:pr-[16px] md:pr-[14px] pr-[9px] md:w-[50px] w-[40px]"
            />
          </div>
          <div className="flex md:justify-normal justify-center">
            <Link
              to="/privacy-policy"
              className="font-normal pr-[10px] md:text-[14px] text-[12px]"
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-and-conditions"
              className="font-normal md:text-[14px] text-[12px]"
            >
              Terms and Conditions
            </Link>
          </div>
        </div>
        <div className="md:col-span-1 md:block hidden"></div>

        <div className="right md:col-span-3 col-span-2">
          <div className=" md:pb-[60px] md:pb-[25px] pb-[15px] md:pt-[50px] md:pt-[35px] pt-[20px]">
            <h1 className="font-bold md:text-[18px] text-[15px]">
              Make moments of your lifetime!
            </h1>
            <p className="font-normal pb-[10px] pt-[13px] md:text-[16px] text-[13px]">
              Stay up-to-date with Presence - Spiritual Dating Enter your email
              to stay updated with new app features and your favorite person.
            </p>
            <h1 className="md:pt-[13px] pt-[9px] pb-[6px] md:text-[18px] text-[15px] font-bold text-center">
              E-mail
            </h1>
            <div className="text-field bg-white lg:py-[10px] py-[4px] rounded-lg flex  justify-between md:px-[10px] px-[3px]">
              <input
                placeholder="E.g. ziggy@space.com"
                className="!text-black md:pr-[px] pr-[0px] outline-none md:rounded-none rounded xl:text-[15px] lg:text-[12px] text-[10px]"
              />
              <button className="bg-[#FFBD12] rounded-md md:py-[5px] py-[2px] lg:px-[25px] px-[10px] font-semibold md:text-[16px] text-[13px] ">
                Subscribe
              </button>
            </div>
            {/* <button className="bg-[#FFBD12] rounded-md md:py-[5px] py-[2px] md:px-[25px] px-[10px] font-semibold md:text-[16px] text-[13px] md:hidden flex   mt-1">
              Subscribe
            </button> */}

            <div className="flex md:py-[17px] pt-[10px] pb-[3px] md:pb-[10px] md:justify-normal justify-center">
              <img src={Location} className="pr-[5px]" />
              <p className="text-[13px]">Blue Area USA</p>
            </div>
            <div className="flex md:justify-normal justify-center">
              <img src={Phone} className="pr-[5px]" />
              <p className="text-[13px]">+1 3214 456 123</p>
            </div>
          </div>
          <p className="text-white text-[10px] md:text-initial text-center">
            © Copyright 2023 Presence - Spiritual Dating
          </p>
        </div>

        <div className="md:col-span-2 hidden md:block"></div>
      </footer>
    </div>
  );
};

export default PrivacyFooter;
