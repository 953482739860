import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useState} from "react";
import {GrFormAdd} from "react-icons/gr";
import {HiTrash} from "react-icons/hi";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useStore} from "../../contexts/StoreProvider";
import axiosInstance from "../../utils/axios";
import {PER_PAGE_DATA_LIMIT} from "../../utils/constants";
import {FiEdit, FiTrash} from "react-icons/fi";
import moment from "moment";
let tableRows = [
    {
        name: "#",
        key: "count",
    },
    {
      name: "Email",
      key: "email",

    },
    {
        name: "Name",
        key: "name",

    },
    {
        name: "Purchase Type",
        key: "purchase_type",
    },
    {
        name: "Count",
        key: "count",
    },
    {name: "Purchased At", key: "createdAt"},

];

const Purchase = () => {
    const [data, setData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const {setLoading} = useStore();
    const [hobby, setHobby] = React.useState({});

    const navigate = useNavigate();


    React.useEffect(() => {
        (async () => {
            setLoading(true);
            await getData();
            setLoading(false);
        })();
    }, []);


    const getData = async (page) => {
        let response = await axiosInstance.get("/users/purchase", {
            params: {
                page: page || currentPage,
                limit: PER_PAGE_DATA_LIMIT,
            },
        });
        if (response?.success) {
            setData(response?.data);
            setCurrentPage(response?.currentPage);
            setTotalPages(response?.totalPages);
        } else {
            toast.error(response?.message);
        }
    };


    return (
        <div>

            {/* Table */}
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
                <table class="w-full text-sm text-left text-gray-500">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-200 border-b-2">
                    <tr>
                        {tableRows?.map((_) => {
                            return (
                                <th scope="col" class="px-6 py-3">
                                    {_?.name}
                                </th>
                            );
                        })}
                        <th scope="col" class="px-6 py-3">
                            <span class="sr-only">Actions</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.map((item, index) => {
                        return (
                            <tr key={item} class="bg-white border-b hover:bg-gray-50">
                                <td class="px-6 py-3">{index + 1}</td>
                                {/* <td class="px-6 py-3">
                    <img
                      src={`${MEDIA_BASE_URL}${item.icon}`}
                      class="h-[70px] w-[70px] rounded"
                    />
                  </td> */}
                                <td class="px-6 py-3">{item?.user_id?.email}</td>
                                <td class="px-6 py-3">{item?.user_id?.name ?  item?.user_id?.name : 'N/A'}</td>
                                <td class="px-6 py-3">{item?.purchase_type}</td>
                                <td class="px-6 py-3">{item?.count}</td>
                                <td class="px-6 py-3">{moment.utc(item?.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            {/* Pagination */}
            <br/>
            {totalPages > 1 && (
                <div className="flex items-center justify-center flex-col">
                    <nav aria-label="Page navigation example" className="mt-3">
                        <ul class="inline-flex -space-x-px">
                            <li>
                                <button
                                    class={`px-3 py-2 ml-0 leading-tight bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 text-gray-700 ${
                                        currentPage === 1 && "!bg-gray-300 hover:bg-gray-300"
                                    }`}
                                    onClick={() => {
                                        getData(parseInt(currentPage) - 1);
                                    }}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                            </li>
                            {Array.from({length: totalPages})?.map((_, i) => {
                                return (
                                    <li key={i}>
                                        <button
                                            class={`px-3 py-2 leading-tight bg-white border border-gray-300 hover:bg-gray-100 text-gray-700 ${
                                                currentPage === i + 1 &&
                                                "!bg-gray-300 hover:bg-gray-300"
                                            }`}
                                            onClick={() => {
                                                getData(i + 1);
                                            }}
                                            disabled={currentPage === i + 1}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                );
                            })}
                            <li>
                                <button
                                    class={`px-3 py-2 ml-0 leading-tight bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 text-gray-700 ${
                                        currentPage === totalPages &&
                                        "!bg-gray-300 hover:bg-gray-300"
                                    }`}
                                    disabled={currentPage === totalPages}
                                    onClick={() => {
                                        getData(parseInt(currentPage) + 1);
                                    }}
                                >
                                    Next
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <span class="text-sm text-gray-700 mt-1">
            Showing{" "}
                        <span class="font-semibold text-gray-700">
              {(parseInt(currentPage) - 1) * PER_PAGE_DATA_LIMIT}
            </span>{" "}
                        to{" "}
                        <span class="font-semibold text-gray-700">
              {parseInt(currentPage) * PER_PAGE_DATA_LIMIT}
            </span>{" "}
                        of{" "}
                        <span class="font-semibold text-gray-700">
              {parseInt(totalPages) * PER_PAGE_DATA_LIMIT}
            </span>{" "}
                        Entries
          </span>
                </div>
            )}
            {/* Pagination ========> */}

            {/* Are you sure you want to delete item */}
            <Transition appear show={isOpen} as={Fragment}>

            </Transition>

        </div>
    );
};

export {Purchase};
