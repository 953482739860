import React from "react";
import FourthSectionImg from "../../assets/04_Section.png";
import FourthSectionImgmbl from "../../assets/04_Section-mbl.png";
import underline from "../../assets/second-section/under-line.svg";
import Fifthsectionboys from "../../assets/fifthsectionboys.png";
import "./sectionFour.css";

const SectionFour = () => {
  return (
    <div className="section-4 2xl:top-[-455px] md:top-[-430px] relative ">
      <div>
        <img
          src={FourthSectionImg}
          className="arrow w-full md:h-[850px]  md:block sm:hidden hidden"
        />
        <img src={FourthSectionImgmbl} className=" md:hidden block w-full" />
      </div>
      <div className="absolute top-[200px]  w-full md:px-[0px] sm:px-[30px] px-[30px]">
        <div className="content text-center  text-white  ">
          <h1 className="md:text-[30px] text-[22px] font-bold md:mt-[0px] mt-[-120px] md:py-[0px] py-[20px]">
            Deepen Your Spiritual Journey Together
          </h1>
          <img
            src={underline}
            alt="Under line"
            className="w-[200px] md:w-[264px] 2xl:w-[304px] 2xl:mr-[957px] sm:mr-[365px] md:mr-[72px] lg:mr-[200px] xl:mr-[315px] sm-m-[auto]  m-[auto]  md:m-[0 0 0 auto] pb-[20px] "
          />
          <h1 className="max-w-[420px]  m-[auto] md:py-[0px] py-[20px]">
            The eyes are the windows to the soul! We encourage a profile's first
            picture to be a selfie or a close up
          </h1>
        </div>
        <div className="grid text-white">
          <div className="images flex justify-center items-center md:flex-row sm:flex-col flex-col">
            <img
              src={require("../../assets/forth-section/card_1.webp")}
              className="lg:w-[280px] md:w-[230px] sm:w-[280px] w-[362px] "
            />
            <img
              src={require("../../assets/forth-section/card_2.webp")}
              className="lg:w-[280px] md:w-[230px] sm:w-[280px] w-[362px]"
            />
            <img
              src={require("../../assets/forth-section/card_3.webp")}
              className="lg:w-[280px] md:w-[230px] sm:w-[280px] w-[362px]"
            />
          </div>
        </div>
      </div>
      <div className="absolute 2xl:right-[100px] md:right-[0px] 2xl:bottom-[55px] bottom-[30px] lg:mr-[70px] md:ml-[0px] ml-[190px]">
        <img
          src={Fifthsectionboys}
          className="xl:w-[200px] lg:w-[150px] md:w-[190px] w-[160px]"
        />
      </div>
    </div>
  );
};

export default SectionFour;
