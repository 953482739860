import React from "react";
import { useParams } from "react-router-dom";
import { useStore } from "../../contexts/StoreProvider";
import HelperFunc from "../../utils/helpers";
import axiosInstance from "../../utils/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import image from "../../assets/images/user.png";

const HobbyDetails = () => {
  const { id } = useParams();
  const [userData, setUserData] = React.useState([]);
  const { setLoading } = useStore();
  let navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, []);

  console.log(id);
  const getData = async (page) => {
    setLoading(true);
    await HelperFunc.wait(1500);
    let response = await axiosInstance.get(`/hobby/${id}`);
    setLoading(false);
    if (response?.success) {
      setUserData(response?.data);
    } else {
      toast.error(response?.message);
    }
  };

  console.log("YYY", userData);
  return (
    <>
      <div className="mt-4 mb-2 flex justify-between font-medium">
        <h1>User Details</h1>
        <button
          onClick={() => navigate(-1)}
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-7 rounded inline-block  "
        >
          Back
        </button>
      </div>
      <div>
        <div className="flex items-center justify-center mb-7">
          <img src={image} className="h-24" />
        </div>
        <div className="text-center font-medium">
          <h5>Name : {userData.name}</h5>
          <h5>Category Id : {userData.category_id}</h5>
          <h5>Color Code : {userData.color_code}</h5>
          <h5>Icon: {userData.icon}</h5>
          <h5>Created At : {userData.created_at}</h5>
          <h5>Updated At : {userData.u}</h5>
        </div>
      </div>
    </>
  );
};

export default HobbyDetails;
