import React from "react";
import { CgLogOut } from "react-icons/cg";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";
import SidebarRoutes from "../navigation/sidebarRoutes";

const Sidebar = () => {
  const { logout } = useAuth();

  return (
    <aside
      id="logo-sidebar"
      className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div className="h-full px-3 pb-4 overflow-y-auto bg-white">
        <ul className="space-y-2">
          {SidebarRoutes?.map(({ icon: Icon, path, name }) => {
            return (
              <li key={path}>
                <NavLink
                  to={path}
                  className={(state) =>
                    `flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${
                      state?.isActive && "bg-gray-100"
                    }`
                  }
                >
                  <Icon />
                  <span className="ml-3">{name}</span>
                </NavLink>
              </li>
            );
          })}
          <li className="absolute bottom-5 w-[90%]">
            <NavLink
              to={""}
              className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg`}
              onClick={logout}
            >
              <CgLogOut className="text-[20px] text-[red]" />
              <span className="ml-3 font-semibold text-[red]">Logout</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
