import React from "react";

const HeroSection = () => {
  return (
    <div className="absolute top-[0px] w-full">
      <div className="lg:px-[70px] md:px-[30px]">
        <div className=" md:grid grid-cols-6 ">
          <div className="lg:col-span-3 md:col-span-3 col-span-6">
            <div className="flex md:justify-start md:text-start sm:justify-center justify-center sm:text-center text-center md:pt-[0px] sm-pt-[30px] pt-[30px] 2xl:pt-[186px]">
              <div className="flex flex-col justify-between 2xl:gap-[400px] 2xl:pl-[400px]">
                <img
                  src={require("../../assets/first-section/Shap.webp")}
                  className="xl:w-[35px] xl:h-[35px] 2xl:w-[50px] 2xl:h-[50px] mt-[80px] w-[30px] h-[30px]  md:block sm:hidden hidden"
                  alt="star"
                />
                <img
                  src={require("../../assets/first-section/shap (1).webp")}
                  className="2xl:h-[50px] h-[40px] ml-[40px] md:ml-[0px] mb-[40px] md:block sm:hidden hidden"
                  alt="curly-line"
                />
              </div>
              <div className="lg:pl-[0px] xl:pl-[90px] md:pl-[40px]">
                <img
                  src={require("../../assets/first-section/Logo-_2.webp")}
                  className="w-[105px]  2xl:w-[200px] lg:w-[105px] xl:w-[110px] md:w-[85px] xl:[163px] h-auto lg:pb-[25px] md:block sm:hidden hidden"
                  alt="heart"
                />
                <h1 className="text-[16px] sm:text-[16px] md:text-[16px] font-bold text-[white] ">
                  Find Your Love
                </h1>
                <h1 className="text-white  font-[700] 2xl:text-[45px] text-[22px] md:text-[13px] lg:text-[26px] xl:text-[26px] 2xl:max-w-[430px] lg:max-w-[240px] 2xl:leading-[60px]   lg:leading-[32px]  sm:max-w-[300px] max-w-[300px]   ">
                  Love is Only A Few Present Moments Away!
                </h1>
                <img
                  src={require("../../assets/first-section/white-underline.webp")}
                  alt=""
                  className="max-w-[260px] md:w-[220px] w-[270px] 2xl:max-w-[540px]  sm-m-[auto]  m-[auto]  md:m-[inherit] pb-[10px]"
                />
                <h1 className="text-[white] text-[16px]  md:text-[12px] xl:text-[14px] 2xl:text-[19px] md:max-w-[330px]  sm:max-w-[300px] max-w-[300px] md:pt-0 pt-[10px]">
                  Explore your spirituality! Gain a deeper understanding of
                  yourself & meet unique partners that share your spiritual
                  beliefs and values!
                </h1>
                <div className="flex  md:justify-start sm:justify-center justify-center pt-[10px]  gap-3 sm:mt-[20px] mt-[20px] md:mt-[0px] ">
                  <img
                    src={require("../../assets/first-section/Google Play.webp")}
                    alt="Google Play"
                    className="lg:w-[130px] lg:h-[38px] h-[32px]  md:h-[28px] md:w-[85px]  2xl:w-[176px] 2xl:h-[50px] "
                  />
                  <img
                    src={require("../../assets/first-section/App Store.webp")}
                    alt="App Store"
                    className="lg:w-[130px] lg:h-[38px] h-[32px] md:h-[28px] md:w-[85px] 2xl:w-[176px] 2xl:h-[50px] "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="image lg:col-span-3 md:col-span-3 col-span-6">
            <img
              src={require("../../assets/first-section/girl.webp")}
              alt="girl"
              className="w-full  md:block sm:hidden hidden"
            />
            <img
              src={require("../../assets/first-section/Logo-_2.webp")}
              className="w-full sm:block md:hidden flex justify-center items-center px-[103px] pt-[30px] pb-[15px]"
              alt="heart"
            />
            <img
              src={require("../../assets/first-section/Shap.webp")}
              className="ml-[10px] mt-[-80px] mb-[70px] w-[22px] h-[22px]  md:hidden sm:block"
              alt="star"
            />
            <img
              src={require("../../assets/first-section/shap (1).webp")}
              className=" h-[30px] ml-[60px] md:ml-[0px] md:hidden sm:block"
              alt="curly-line"
            />
            <img
              src={require("../../assets/first-section/banner-img.webp")}
              alt="girl"
              className="w-full  md:hidden sm:block block sm:px-[20px] px-[20px] mt-[-80px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
