class HelperClass {
  isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  parseJson(jsonString) {
    let jsonObj = null;

    try {
      jsonObj = JSON.parse(jsonString);
    } catch (error) {
      return null;
    }

    if (typeof jsonObj !== "object" || jsonObj === null) {
      return null;
    }

    return jsonObj;
  }

  wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

const HelperFunc = new HelperClass();

export default HelperFunc;
