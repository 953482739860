import React from "react";
import image from "../../assets/images/user.png";
import {useStore} from "../../contexts/StoreProvider";
import {useParams} from "react-router-dom";
import HelperFunc from "../../utils/helpers";
import axiosInstance from "../../utils/axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {MEDIA_BASE_URL} from "../../utils/constants";
import moment from "moment";

const UserDetails = () => {
    const {id} = useParams();
    const [user, setUser] = React.useState([]);
    const {setLoading} = useStore();
    let navigate = useNavigate();

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async (page) => {
        setLoading(true);
        let response = await axiosInstance.get(`/users/${id}`);
        setLoading(false);
        if (response?.success) {
            setUser(response?.data ?? {});
        } else {
            toast.error(response?.message);
        }
    };

    return (
        <>
            <div className="mt-2 mb-2 flex justify-between font-medium">
                <h1 className="text-2xl">User Details</h1>
                <button
                    onClick={() => navigate(-1)}
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-7 rounded inline-block  "
                >
                    Back
                </button>
            </div>

            {/* User Card */}
            <div className="bg-white my-2 py-3.5 px-5 rounded-md shadow-lg mt-5">
                <div className="flex flex-row items-center">
                    <div>
                        <img
                            src={
                                user?.profile_picture
                                    ? `${MEDIA_BASE_URL}${user?.profile_picture}`
                                    : image
                            }
                            className="h-24 w-24 rounded-full border"
                        />
                    </div>
                    <div className="ml-5">
                        <p className="text-3xl flex items-center ">
                            <span className="ml-2">{user?.name ?? "N/A"}</span>
                            {/* <span class="ml-2 text-green-800 bg-green-100 text-sm font-medium py-0.5 px-2 rounded-full">
                {user?.status}
              </span> */}
                        </p>
                        <p>
              <span className="ml-2">
                {user?.email ?? "N/A"} - {user?.auth_provider}
              </span>
                        </p>
                    </div>
                </div>

                <div className="mt-3"/>
                {/* User Detail */}
                <p class="py-1">
                    <b>Gender:</b>
                    <span className="capitalize ml-2">{user?.gender ?? "N/A"}</span>
                </p>
                <p class="py-1">
                    <b>Date of Birth:</b>
                    <span
                        className="capitalize ml-2">{moment.unix(user?.date_of_birth).format("DD/MM/YYYY") ?? "N/A"}</span>
                </p>
                <p class="py-1">
                    <b>Interested Gender:</b>
                    <span className="capitalize ml-2">
            {user?.interested_gender ?? "N/A"}
          </span>
                </p>
                <p class="py-1">
                    <b>Zodiac Sign:</b>
                    <span className="capitalize ml-2">
            {user?.zodiac_sign ?? "N/A"}
          </span>
                </p>
                <p class="py-1">
                    <b>Relation Timeline:</b>
                    <span className="capitalize ml-2">
            {user?.relation_timeline ?? "N/A"}
          </span>
                </p>
                <p class="py-1">
                    <b>Yoga Time:</b>
                    <span className="capitalize ml-2">{user?.yoga_time ?? "N/A"}</span>
                </p>

                <p class="py-1">
                    <b>Reading Time:</b>
                    <span className="capitalize ml-2">{user?.reading_time ?? "N/A"}</span>
                </p>

                <p class="py-1">
                    <b>SuperLikes:</b>
                    <span className="capitalize ml-2">{user?.superLikeCount ?? "N/A"}</span>
                </p>
                <p class="py-1">
                    <b>Boosts:</b>
                    <span className="capitalize ml-2">{user?.boostCount ?? "N/A"}</span>
                </p>
                <p className="py-1">
                    <b>Notes:</b>
                    <span className="capitalize ml-2">{user?.notesCount ?? "N/A"}</span>
                </p>
                <p className="py-1">
                    <b>Gifts:</b>
                    <span className="capitalize ml-2">{user?.giftCount ?? "N/A"}</span>
                </p>

                <p class="py-1">
                    <b>Created At:</b>
                    <span className="capitalize ml-2">
            {moment.utc(user?.created_at).format("DD/MM/YYYY") ?? "N/A"}
          </span>
                </p>
            </div>
            <div></div>
            {/* <div>
        <div className="flex items-center justify-center mb-7">
          <img
            src={
              userData.profile_picture
                ? `${MEDIA_BASE_URL}${userData.profile_picture}`
                : image
            }
            className="h-24 w-24 rounded-full"
          />
        </div>
        <div className="text-center">
          <h5>
            <b>Name</b> : {user?.name ?? "N/A"}
          </h5>
          <h5>
            <b>Email</b> : {user?.email ?? "N/A"}
          </h5>
          <h5>
            <b>Gender</b> : {user?.gender ?? "N/A"}
          </h5>
          <h5>
            <b>Stars</b>: {user?.stars ?? "N/A"}
          </h5>
          <h5>
            <b>Status</b> : {user?.status ?? "N/A"}
          </h5>
        </div>
      </div> */}
        </>
    );
};

export {UserDetails};
