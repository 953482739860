import React from "react";
import PrivacyHeader from "./header";
import Privacy_Footer from "./footer";
const PrivacyPolicy = () => {
  return (
    <>
      <PrivacyHeader />
      <section className="overflow-hidden content  lg:mx-[280px] md:mx-[70px] mx-[30px] md:mb-[100px] mb-[40px]">
        <p className="text-[15px] font-medium  pt-[40px] pb-[10px]">
          This privacy notice for Triple Realms LLC ("<b>Company</b>", "{" "}
          <b>we</b>", " <b>us</b>", "<b>or</b>" "<b>our</b> "), describes how
          and why we might collect, store, use, and/or share ("<b>process</b>")
          your information when you use our services ("<b>Services</b>"), such
          as when you:
          <br />
          <br />
          <ul className="list-disc pl-[30px]">
            <li>
              Visit our website at{" "}
              <a
                href="https://spiritualdatingapp.com"
                className="font-semibold"
              >
                https://spiritualdatingapp.com
              </a>
              , or any website of ours that links to this privacy notice
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <p className="py-2">
            <span className="font-bold">Questions or concerns?</span> Reading
            this privacy notice will help you understand your privacy rights and
            choices. If you do not agree with our policies and practices, please
            do not use our Services. If you still have any questions or
            concerns, please contact us at info@triplerealms.com.
          </p>
        </p>
        <h1 className="font-bold pt-[25px] pb-[15px]">Summary Of Key Points</h1>
        <p className="text-[15px] font-bold">
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for. You can also click{" "}
          <a href="#" className="underline">
            here
          </a>{" "}
          to go directly to our table of contents.
        </p>
        <br />
        <p className="text-[15px] font-normal">
          <span className="font-bold">
            What personal information do we process?
          </span>
          &nbsp; When you visit, use, or navigate our Services, we may process
          personal information depending on how you interact with Triple Realms
          LLC and the Services, the choices you make, and the products and
          features you use. Click{" "}
          <a href="#" className="underline">
            here
          </a>{" "}
          to learn more.
        </p>
        <br />
        <p className="text-[15px] font-normal">
          <span className="font-bold">
            Do we process any sensitive personal information? &nbsp;
          </span>
          We do not process sensitive personal information.
        </p>
        <br />
        <p className="text-[15px] font-normal">
          <span className="font-bold">
            Do we receive any information from third parties? &nbsp;
          </span>
          We do not receive any information from third parties.
        </p>
        <br />
        <p className="text-[15px] font-normal">
          <span className="font-bold">
            How do we process your information? &nbsp;
          </span>
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so. Click{" "}
          <a href="#" className="underline">
            here
          </a>{" "}
          to learn more.
        </p>
        <br />
        <p className="text-[15px] font-normal">
          <span className="font-bold">
            In what situations and with which types of parties do we share
            personal information? &nbsp;
          </span>
          We may share information in specific situations and with specific
          categories of third parties. Click{" "}
          <a href="#" className="underline">
            here
          </a>{" "}
          to learn more.
        </p>
        <br />
        <p className="text-[15px] font-normal">
          <span className="font-bold">
            How do we keep your information safe?&nbsp;
          </span>
          We have organizational and technical processes and procedures in place
          to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorized third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Click{" "}
          <a href="#" className="underline">
            here
          </a>{" "}
          to learn more.
        </p>
        <br />
        <p className="text-[15px] font-normal">
          <span className="font-bold">What are your rights? &nbsp;</span>
          Depending on where you are located geographically, the applicable
          privacy law may mean you have certain rights regarding your personal
          information. Click{" "}
          <a href="#" className="underline">
            here
          </a>{" "}
          to learn more.
        </p>
        <br />
        <p className="text-[15px] font-normal">
          <span className="font-bold">
            How do you exercise your rights? &nbsp;
          </span>
          The easiest way to exercise your rights is by filling out our data
          subject request form available{" "}
          <a href="#" className="underline">
            here
          </a>
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
        <br />
        <p className="text-[15px] font-normal">
          Want to learn more about what Triple Realms LLC does with any
          information we collect? Click{" "}
          <a href="#" className="underline">
            here
          </a>{" "}
          to review the notice in full.
        </p>
        <h1 className="font-bold pt-[25px] pb-[15px]">TABLE OF CONTENTS</h1>
        <h1>
          <a href="#point-1">1. WHAT INFORMATION DO WE COLLECT?</a>
        </h1>
        <h1>
          <a href="#point-2">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
        </h1>
        <h1>
          <a href="#point-3">
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
            INFORMATION?
          </a>
        </h1>
        <h1>
          <a href="#point-4">
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>{" "}
        </h1>
        <h1>
          <a href="#point-5">5.WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a>
        </h1>
        <h1>
          <a href="#point-6">
            6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
        </h1>
        <h1>
          <a href="#point-7">7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
        </h1>
        <h1>
          <a href="#point-8">8. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
        </h1>
        <h1>
          <a href="#point-9">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
        </h1>
        <h1>
          <a href="#point-10">10. DO WE COLLECT INFORMATION FROM MINORS?</a>
        </h1>
        <h1>
          <a href="#point-11">11. WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </h1>
        <h1>
          <a href="#point-12">12. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </h1>
        <h1>
          <a href="#point-13">
            13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </h1>
        <h1>
          <a href="#point-14">14. DO WE MAKE UPDATES TO THIS NOTICE?</a>
        </h1>
        <h1>
          <a href="#point-15">15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
        </h1>
        <h1>
          <a href="#point-16">
            16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </a>
        </h1>
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-1">
          1. WHAT INFORMATION DO WE COLLECT?
        </h1>
        <h1 className="font-bold">Personal information you disclose to us</h1>
        <br />
        <p>
          <span className="font-bold">
            <i>In Short:</i>
          </span>{" "}
          <i>We collect personal information that you provide to us.</i>
        </p>
        <br />
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us
        </p>
        <br />
        <p>
          <span className="font-semibold">
            Personal Information Provided by You.
          </span>{" "}
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include the following:
        </p>
        <br />
        <ul className="list-disc pl-[30px]">
          <li>names</li>
          <li>email addresses</li>
          <li>mailing addresses</li>
          <li>usernames</li>
          <li>passwords</li>
          <li>billing addresses</li>
          <li>debit/credit card numbers</li>
        </ul>
        <p className="text-[15px]">
          <span className="font-semibold">Sensitive Information.</span> We do
          not process sensitive information.
        </p>
        <br />
        <p>
          <span className="font-semibold">Payment Data.</span> We may collect
          data necessary to process your payment if you make purchases, such as
          your payment instrument number, and the security code associated with
          your payment instrument. All payment data is stored by Payment Cloud
          Inc. You may find their privacy notice link(s) here:{" "}
          <a href="#" className="underline">
            https://paymentcloudinc.com/privacy/.
          </a>
        </p>
        <br />
        <p>
          <span className="font-semibold">Social Media Login Data.</span> We may
          provide you with the option to register with us using your existing
          social media account details, like your Facebook, Twitter, or other
          social media account. If you choose to register in this way, we will
          collect the information described in the section called{" "}
          <a href="#" className="underline">
            "HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
          </a>{" "}
          below.
        </p>
        <br />
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information
        </p>
        <h1 className="font-bold pt-[25px] pb-[15px]">
          Information automatically collected
        </h1>
        <p>
          <span className="font-semibold">
            <i>In Short:</i>
          </span>
          <i>
            Some information — such as your Internet Protocol (IP) address
            and/or browser and device characteristics — is collected
            automatically when you visit our Services.
          </i>
        </p>
        <br />
        <p className="text-[15px]">
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <br />
        <p className="text-[15px]">
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <br />
        <p>The information we collect includes:</p>
        <br />
        <ul className="list-disc pl-[30px]">
          <li>
            <i>Log and Usage Data</i> . Log and usage data is service-related,
            diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type, and settings and information about your activity in the
            Services (such as the date/time stamps associated with your usage,
            pages and files viewed, searches, and other actions you take such as
            which features you use), device event information (such as system
            activity, error reports (sometimes called "crash dumps"), and
            hardware settings).
          </li>
          <li>
            <i>Device Data</i>. We collect device data such as information about
            your computer, phone, tablet, or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </li>
          <li>
            <i>Location Data</i>. We collect location data such as information
            about your device's location, which can be either precise or
            imprecise. How much information we collect depends on the type and
            settings of the device you use to access the Services. For example,
            we may use GPS and other technologies to collect geolocation data
            that tells us your current location (based on your IP address). You
            can opt out of allowing us to collect this information either by
            refusing access to the information or by disabling your Location
            setting on your device. However, if you choose to opt out, you may
            not be able to use certain aspects of the Services.
          </li>
        </ul>
        <br />
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-2">
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </h1>
        <p className="text-[15px]">
          <span className="font-semibold">
            <i>In Short:</i>
          </span>
          <i>
            We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention,
            and to comply with law. We may also process your information for
            other purposes with your consent.
          </i>
        </p>
        <br />
        <p className="text-[15px]">
          <b>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </b>
        </p>
        <br />
        <ul className="list-disc pl-[30px]">
          <li>
            <span className="font-semibold">
              To facilitate account creation and authentication and otherwise
              manage user accounts.
            </span>
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order
          </li>
          <li>
            <span className="font-semibold">
              To deliver and facilitate delivery of services to the user.
            </span>
            We may process your information to provide you with the requested
            service.
          </li>
          <li>
            <span className="font-semibold">
              To respond to user inquiries/offer support to users.
            </span>
            We may process your information to respond to your inquiries and
            solve any potential issues you might have with the requested
            service.
          </li>
          <li>
            <span className="font-semibold">
              To fulfill and manage your orders.
            </span>
            We may process your information to fulfill and manage your orders,
            payments, returns, and exchanges made through the Services.
          </li>
          <li>
            <span>To enable user-to-user communications.</span>
            We may process your information if you choose to use any of our
            offerings that allow for communication with another user.
          </li>
          <li>
            <span className="font-semibold">
              To save or protect an individual's vital interest.
            </span>
            We may process your information when necessary to save or protect an
            individual’s vital interest, such as to prevent harm.
          </li>
        </ul>
        <br />
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-3">
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
        </h1>
        <p className="text-[15px]">
          <span className="font-semibold">
            <i>In Short:</i>
          </span>{" "}
          <i>
            {" "}
            We only process your personal information when we believe it is
            necessary and we have a valid legal reason (i.e., legal basis) to do
            so under applicable law, like with your consent, to comply with
            laws, to provide you with services to enter into or fulfill our
            contractual obligations, to protect your rights, or to fulfill our
            legitimate business interests.
          </i>
        </p>
        <br />
        <p className="text-[15px] underline">
          <i>
            If you are located in the EU or UK, this section applies to you.
          </i>
        </p>
        <br />
        <p className="text-[15px]">
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <br />
        <ul className="list-disc pl-[30px]">
          <li>
            <span className="font-semibold">Consent.</span>
            We may process your information if you have given us permission
            (i.e., consent) to use your personal information for a specific
            purpose. You can withdraw your consent at any time. Click here to
            learn more
          </li>
          <li>
            <span className="font-semibold">Performance of a Contract.</span>
            We may process your personal information when we believe it is
            necessary to fulfill our contractual obligations to you, including
            providing our Services or at your request prior to entering into a
            contract with you.
          </li>
          <li>
            <span className="font-semibold">Legal Obligations.</span>
            We may process your information where we believe it is necessary for
            compliance with our legal obligations, such as to cooperate with a
            law enforcement body or regulatory agency, exercise or defend our
            legal rights, or disclose your information as evidence in litigation
            in which we are involved.
          </li>
          <li>
            <span className="font-semibold">Vital Interests.</span>
            We may process your information where we believe it is necessary to
            protect your vital interests or the vital interests of a third
            party, such as situations involving potential threats to the safety
            of any person.
          </li>
        </ul>
        <br />
        <p className="text-[15px] underline">
          <i>If you are located in Canada, this section applies to you.</i>
        </p>
        <br />
        <p className="text-[15px]">
          We may process your information if you have given us specific
          permission (i.e., express consent) to use your personal information
          for a specific purpose, or in situations where your permission can be
          inferred (i.e., implied consent). You can withdraw your consent at any
          time. Click{" "}
          <span className="underline">
            <a href="#">here</a>
          </span>{" "}
          to learn more.
        </p>
        <br />
        <p className="text-[15px]">
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>
        <br />
        <ul className="list-disc pl-[30px]">
          <li>
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
          </li>
          <li>For investigations and fraud detection and prevention</li>
          <li>For business transactions provided certain conditions are met</li>
          <li>
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim
          </li>
          <li>
            For identifying injured, ill, or deceased persons and communicating
            with next of kin
          </li>
          <li>
            If we have reasonable grounds to believe an individual has been, is,
            or may be victim of financial abuse
          </li>
          <li>
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province
          </li>
          <li>
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records
          </li>
          <li>
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </li>
          <li>
            If the collection is solely for journalistic, artistic, or literary
            purposes
          </li>
          <li>
            If the information is publicly available and is specified by the
            regulations
          </li>
        </ul>
        <br />
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-4">
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h1>

        <p className="text-[15px]">
          <span className="font-semibold">
            <i>In Short:</i>
          </span>{" "}
          <i>
            We may share information in specific situations described in this
            section and/or with the following categories of third parties.
          </i>
        </p>
        <br />
        <p className="text-[15px]">
          <span className="font-semibold">
            Vendors, Consultants, and Other Third-Party Service Providers.
          </span>{" "}
          We may share your data with third-party vendors, service providers,
          contractors, or agents ("
          <span className="font-semibold">third parties</span>") who perform
          services for us or on our behalf and require access to such
          information to do that work. We have contracts in place with our third
          parties, which are designed to help safeguard your personal
          information. This means that they cannot do anything with your
          personal information unless we have instructed them to do it. They
          will also not share your personal information with any organization
          apart from us. They also commit to protect the data they hold on our
          behalf and to retain it for the period we instruct. The categories of
          third parties we may share personal information with are as follows:
        </p>
        <br />
        <ul className="list-disc pl-[30px]">
          <li>Ad Networks</li>
          <li>Affiliate Marketing Programs</li>
          <li>Payment Processors</li>
          <li>Order Fulfillment Service Providers</li>
          <li>Sales & Marketing Tools</li>
        </ul>
        <br />
        <p className="text-[15px]">
          We also may need to share your personal information in the following
          situations:
        </p>
        <br />
        <ul className="list-disc pl-[30px]">
          <li>
            <span className="font-semibold">Business Transfers.</span>
            We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to
            another company.
          </li>
          <li>
            <span className="font-semibold">Affiliates.</span>
            We may share your information with our affiliates, in which case we
            will require those affiliates to honor this privacy notice.
            Affiliates include our parent company and any subsidiaries, joint
            venture partners, or other companies that we control or that are
            under common control with us
          </li>
          <li>
            <span className="font-semibold">Affiliates.</span>
            Business Partners. We may share your information with our business
            partners to offer you certain products, services, or promotions.
          </li>
          <li>
            <span className="font-semibold">Other Users.</span>
            When you share personal information (for example, by posting
            comments, contributions, or other content to the Services) or
            otherwise interact with public areas of the Services, such personal
            information may be viewed by all users and may be publicly made
            available outside the Services in perpetuity. If you interact with
            other users of our Services and register for our Services through a
            social network (such as Facebook), your contacts on the social
            network will see your name, profile photo, and descriptions of your
            activity. Similarly, other users will be able to view descriptions
            of your activity, communicate with you within our Services, and view
            your profile.
          </li>
        </ul>
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-5">
          5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
        </h1>

        <p className="text-[15px]">
          <span className="font-semibold">
            <i>In Short:</i>
          </span>{" "}
          <i>
            {" "}
            We are not responsible for the safety of any information that you
            share with third parties that we may link to or who advertise on our
            Services, but are not affiliated with, our Services.
          </i>
        </p>
        <br />
        <p className="text-[15px]">
          The Services may link to third-party websites, online services, or
          mobile applications and/or contain advertisements from third parties
          that are not affiliated with us and which may link to other websites,
          services, or applications. Accordingly, we do not make any guarantee
          regarding any such third parties, and we will not be liable for any
          loss or damage caused by the use of such third-party websites,
          services, or applications. The inclusion of a link towards a
          third-party website, service, or application does not imply an
          endorsement by us. We cannot guarantee the safety and privacy of data
          you provide to any third parties. Any data collected by third parties
          is not covered by this privacy notice. We are not responsible for the
          content or privacy and security practices and policies of any third
          parties, including other websites, services, or applications that may
          be linked to or from the Services. You should review the policies of
          such third parties and contact them directly to respond to your
          questions.
        </p>
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-6">
          6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h1>

        <p className="text-[15px]">
          <span className="font-semibold">
            <i>In Short:</i>
          </span>{" "}
          <i>
            We may use cookies and other tracking technologies to collect and
            store your information.
          </i>
        </p>
        <br />
        <p className="text-[15px]">
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>
        <br />
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-7">
          7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </h1>

        <p className="text-[15px]">
          <span className="font-semibold">
            <i>In Short:</i>
          </span>{" "}
          <i>
            If you choose to register or log in to our Services using a social
            media account, we may have access to certain information about you..
          </i>
        </p>
        <br />
        <p className="text-[15px] ">
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
        </p>
        <br />
        <p className="text-[15px] ">
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-8">
          8. HOW LONG DO WE KEEP YOUR INFORMATION?
        </h1>

        <p className="text-[15px]">
          <span className="font-semibold">
            <i>In Short:</i>
          </span>{" "}
          <i>
            {" "}
            We keep your information for as long as necessary to fulfill the
            purposes outlined in this privacy notice unless otherwise required
            by law.
          </i>
        </p>
        <br />
        <p className="text-[15px] ">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <br />
        <p className="text-[15px] ">
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-9">
          9. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </h1>

        <p className="text-[15px]">
          <span className="font-semibold">
            <i>In Short:</i>
          </span>{" "}
          <i>
            We aim to protect your personal information through a system of
            organizational and technical security measures.
          </i>
        </p>
        <br />
        <p className="text-[15px] ">
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>

        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-10">
          10. DO WE COLLECT INFORMATION FROM MINORS?
        </h1>
        <p className="text-[15px]">
          <span className="font-semibold">
            <i>In Short:</i>
          </span>
          <i>
            {" "}
            We do not knowingly collect data from or market to children under 18
            years of age.
          </i>
        </p>
        <br />
        <p className="text-[15px] ">
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at{" "}
          <span className="font-semibold underline">
            <a href="#">info@triplerealms.com</a>
          </span>{" "}
          .
        </p>
        <br />
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-11">
          11. WHAT ARE YOUR PRIVACY RIGHTS?
        </h1>
        <p className="text-[15px]">
          <span className="font-semibold">
            <i>In Short:</i>
          </span>{" "}
          <i>
            In some regions, such as the European Economic Area (EEA), United
            Kingdom (UK), and Canada, you have rights that allow you greater
            access to and control over your personal information. You may
            review, change, or terminate your account at any time.
          </i>
        </p>
        <br />
        <p className="text-[15px] ">
          In some regions (like the EEA, UK, and Canada), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. You can make such a request by contacting us by using the
          contact details provided in the section "
          <span className="font-semibold underline"></span> " below.
        </p>
        <br />
        <p className="text-[15px]">
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <br />
        <p className="text-[15px]">
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your local data protection supervisory authority. You can
          find their contact details here:
          <a href="#" className="underline">
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html.
          </a>
        </p>
        <br />
        <p className="text-[15px]">
          If you are located in Switzerland, the contact details for the data
          protection authorities are available here:
          <a href="#" className="underline">
            https://www.edoeb.admin.ch/edoeb/en/home.html.
          </a>
        </p>
        <br />
        <p className="text-[15px]">
          <span className="font-semibold underline">
            Withdrawing your consent:
          </span>{" "}
          If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section "
          <a href="#" className="underline">
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
          " below.
        </p>
        <br />
        <p className="text-[15px]">
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <br />
        <p className="text-[15px]">
          <span className="font-semibold underline">
            <a href="">
              Opting out of marketing and promotional communications:
            </a>{" "}
          </span>{" "}
          You can unsubscribe from our marketing and promotional communications
          at any time by clicking on the unsubscribe link in the emails that we
          send, replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we
          send, or by contacting us using the details provided in the section "
          <a href="#" className="underline">
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
          " below. You will then be removed from the marketing lists. However,
          we may still communicate with you — for example, to send you
          service-related messages that are necessary for the administration and
          use of your account, to respond to service requests, or for other
          non-marketing purposes.
        </p>
        <br />
        <h1 className="font-semibold">Account Information</h1>
        <br />
        <p className="text-[15px]">
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <br />
        <ul className="list-disc pl-[30px]">
          <li>
            Log in to your account settings and update your user account. Upon
            your request to terminate your account, we will deactivate or delete
            your account and information from our active databases. However, we
            may retain some information in our files to prevent fraud,
            troubleshoot problems, assist with any investigations, enforce our
            legal terms and/or comply with applicable legal requirements.
          </li>
        </ul>
        <br />
        <p className="text-[15px]">
          <span className="font-semibold underline">
            <a href="">Cookies and similar technologies:</a>{" "}
          </span>{" "}
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. To opt
          out of interest-based advertising by advertisers on our Services visit
          <a href="#">http://www.aboutads.info/choices/</a>.
        </p>
        <br />
        <p className="text-[15px]">
          If you have questions or comments about your privacy rights, you may
          email us at
          <span>
            <a href="#">info@triplerealms.com</a>
          </span>
          .
        </p>
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-12">
          12. CONTROLS FOR DO-NOT-TRACK FEATURES
        </h1>
        <br />
        <p className="text-[15px]">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <h1 className="font-bold pt-[25px] pb-[15px]" id="point-13">
          13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h1>
        <p>
          <span className="font-bold">
            <i>In Short:</i>
          </span>{" "}
          <i>
            Yes, if you are a resident of California, you are granted specific
            rights regarding access to your personal information.
          </i>
        </p>
        <br />
        <p className="text-[15px]">
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <br />
        <br />
        <p className="text-[15px]">
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g., backups, etc.).
        </p>
        <br />
        <h1 className="font-semibold">CCPA Privacy Notice</h1>
        <br />
        <p className="text-[15px]">
          The California Code of Regulations defines a "resident" as:
        </p>
        <br />
        <p className="text-[15px]">
          (1) every individual who is in the State of California for other than
          a temporary or transitory purpose and
        </p>
        <p className="text-[15px]">
          (2) every individual who is domiciled in the State of California who
          is outside the State of California for a temporary or transitory
          purpose
        </p>
        <br />
        <p className="text-[15px]">
          All other individuals are defined as "non-residents."
        </p>
        <br />
        <p className="text-[15px]">
          If this definition of "resident" applies to you, we must adhere to
          certain rights and obligations regarding your personal information.
        </p>
        <br />
        <h1 className="font-bold text-[13px]">
          What categories of personal information do we collect?
        </h1>
        <br />
        <p className="text-[15px]">
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>
        <br />

        <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Category{" "}
                </th>
                <th scope="col" class="px-6 py-3">
                  Examples
                </th>
                <th scope="col" class="px-6 py-3">
                  Collected
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b  dark:border-gray-700">
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  A. Identifiers
                </td>
                <td class="px-6 py-4">
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td class="px-6 py-4">NO</td>
              </tr>
              <tr class="bg-white border-b  dark:border-gray-700">
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  B. Personal information categories <br /> listed in the
                  California
                  <br />
                  Customer Records statuteo
                </td>
                <td class="px-8 py-4">
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td class="px-6 py-4">NO</td>
              </tr>
              <tr class="bg-white border-b ">
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  C. Protected classification <br /> characteristics under
                  California or <br /> federal law
                </td>
                <td class="px-6 py-4">Gender and date of birth</td>
                <td class="px-6 py-4">NO</td>
              </tr>
              <tr class="bg-white border-b ">
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  D. Commercial information
                </td>
                <td class="px-6 py-4">
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td class="px-6 py-4">NO</td>
              </tr>
              <tr class="bg-white border-b ">
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  E. Biometric information
                </td>
                <td class="px-6 py-4">Fingerprints and voiceprints</td>
                <td class="px-6 py-4">NO</td>
              </tr>
              <tr class="bg-white border-b ">
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  F. Internet or other similar <br /> network activity
                </td>
                <td class="px-6 py-4">
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td class="px-6 py-4">YES</td>
              </tr>
              <tr class="bg-white border-b ">
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  G. Geolocation data
                </td>
                <td class="px-6 py-4">Device location</td>
                <td class="px-6 py-4">NO</td>
              </tr>
              <tr class="bg-white border-b ">
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  H. Audio, electronic, visual,
                  <br /> thermal, olfactory,
                  <br /> or similar H. Audio, electronic, visual,
                  <br /> thermal, olfactory, or similar information
                </td>
                <td class="px-6 py-4">
                  Images and audio, video or call recordings created in <br />{" "}
                  connection with our business activities
                </td>
                <td class="px-6 py-4">NO</td>
              </tr>
              <tr class="bg-white border-b ">
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  I. Professional or employment-related <br /> information
                </td>
                <td class="px-6 py-4">
                  Images and audio, video or call recordings created in <br />{" "}
                  connection with our business activities
                </td>
                <td class="px-6 py-4">NO</td>
              </tr>
              <tr class="bg-white border-b ">
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  J. Education Information
                </td>
                <td class="px-6 py-4">
                  Student records and directory information
                </td>
                <td class="px-6 py-4">NO</td>
              </tr>
              <tr class="bg-white border-b ">
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  K. Inferences drawn from other <br /> personal information
                </td>
                <td class="px-6 py-4">
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics
                </td>
                <td class="px-6 py-4">NO</td>
              </tr>
            </tbody>
          </table>
          <br />
          <p className="text-[15px]">
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </p>
          <br />
          <ul className="list-disc pl-[30px]">
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>
          <h1 className="font-semibold text-[13px]">
            How do we use and share your personal information?
          </h1>
          <br />
          <p className="text-[15px]">
            Triple Realms LLC collects and shares your personal information
            through:
          </p>
          <br />
          <ul className="list-disc pl-[30px]">
            <li>Targeting cookies/Marketing cookies</li>
            <li>Social media cookies</li>
          </ul>
          <p className="text-[15px]">
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>
          <br />
          <p className="text-[15px]">
            You may contact us by email at{" "}
            <a href="#" className="underline">
              {" "}
              info@triplerealms.com,
            </a>{" "}
            by visiting litlounge.io/contact, or by referring to the contact
            details at the bottom of this document.
          </p>
          <p className="text-[15px]">
            If you are using an authorized agent to exercise your right to opt
            out we may deny a request if the authorized agent does not submit
            proof that they have been validly authorized to act on your behalf.
          </p>

          <br />
          <h1 className="font-semibold text-[13px]">
            Will your information be shared with anyone else?
          </h1>
          <br />
          <p className="text-[15px]">
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf.
          </p>
          <br />
          <p className="text-[15px]">
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal information.
          </p>
          <br />
          <p className="text-[15px]">
            <span className="font-semibold">
              Triple Realms LLC has not sold any personal information to third
              parties for a business or commercial purpose in the preceding
              twelve (12) months.
            </span>{" "}
            Triple Realms LLC has disclosed the following categories of personal
            information to third parties for a business or commercial purpose in
            the preceding twelve (12) months:
          </p>
          <p className="text-[15px]">
            The categories of third parties to whom we disclosed personal
            information for a business or commercial purpose can be found under
            "
            <a href="#" className="underline">
              {" "}
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
            ".
          </p>
          <br />
          <h1 className="font-semibold text-[13px]">
            Your rights with respect to your personal data
          </h1>
          <br />
          <p className="underline text-[15px]">
            Right to request deletion of the data — Request to delete
          </p>
          <br />
          <p className="text-[15px]">
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities.
          </p>
          <br />
          <p className="text-[15px]">Right to be informed — Request to know</p>
          <br />
          <p className="text-[15px]">
            Depending on the circumstances, you have a right to know:
          </p>
          <ul className="list-disc pl-[30px]">
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>
              the purposes for which the collected personal information is used;
            </li>
            <li>whether we sell your personal information to third parties;</li>
            <li>
              the categories of personal information that we sold or disclosed
              for a business purpose;
            </li>
            <li>
              the categories of third parties to whom the personal information
              was sold or disclosed for a business purpose; and
            </li>
            <li>
              the business or commercial purpose for collecting or selling
              personal information. In accordance with applicable law, we are
              not obligated to provide or delete consumer information that is
              de-identified in response to a consumer request or to re-identify
              individual data to verify a consumer request.
            </li>
          </ul>
          <br />
          <p className="underline text-[15px]">
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
            Rights
          </p>
          <br />
          <p className="text-[15px]">
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>
          <br />
          <p className="text-[15px] underline">Verification process</p>
          <br />
          <p className="text-[15px] ">
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g., phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>
          <br />
          <p className="text-[15px] ">
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>
          <br />
          <p className="text-[15px] underline">Other privacy rights</p>
          <ul className="list-disc pl-[30px]">
            <li>
              You may object to the processing of your personal information.
            </li>
            <li>
              You may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the information.
            </li>
            <li>
              You can designate an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </li>
            <li>
              You may request to opt out from future selling of your personal
              information to third parties. Upon receiving an opt-out request,
              we will act upon the request as soon as feasibly possible, but no
              later than fifteen (15) days from the date of the request
              submission. To exercise these rights, you can contact us by email
              at info@triplerealms.com, by visiting litlounge.io/contact, or by
              referring to the contact details at the bottom of this document.
              If you have a complaint about how we handle your data, we would
              like to hear from you.
            </li>
          </ul>
          <h1 className="font-bold pt-[25px] pb-[15px]" id="point-14">
            14. DO WE MAKE UPDATES TO THIS NOTICE?
          </h1>
          <p className="text-[15px]">
            <span className="font-bold">
              <i>In Short:</i>
            </span>{" "}
            <i>
              {" "}
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </i>
          </p>
          <br />
          <p className="text-[15px]">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information
          </p>
          <br />
          <h1 className="font-bold pt-[25px] pb-[15px]" id="point-15">
            15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </h1>
          <br />
          <p className="text-[15px]">
            If you have questions or comments about this notice, you may contact
            our Data Protection Officer (DPO), Data Protection Officer, by email
            at info@triplerealms.com, by phone at 13072012266, or by post to:
          </p>
          <br />
          <p>Triple Realms LLC</p>
          <p>Data Protection Officer</p>
          <p>30 N Gould St.</p>
          <p>STE R</p>
          <p>Sheridan, WY 82801</p>
          <p>United States</p>
          <h1 className="font-bold pt-[25px] pb-[15px]" id="point-16">
            16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h1>
          <p className="text-[15px]">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please submit a request form by
            clicking{" "}
            <a href="#" className="underline">
              here
            </a>
            .
          </p>
        </div>
      </section>
      <Privacy_Footer />
    </>
  );
};

export default PrivacyPolicy;
