import { AdminInfo, Hobbies, Login, Users } from "../screens";
import HobbyDetails from "../screens/Hobbies/hobbyDetails";
import { HobbyCategory } from "../screens/HobbyCategory/hobbyCategory";
import { HomePage } from "../screens/HomePage";
import landingPage from "../screens/LandingPage_2/index";
import { Questions } from "../screens/Questions/questions";
import { UserDetails } from "../screens/Users/UserDetails";
import { Purchase } from "../screens/Purchases";
import { MapPage } from "../screens/UsersMap/MapPage";

// Default isPrivate value is false
const Routes = [
  // Public Routes
  {
    path: "/admin/login",
    component: Login,
  },
  {
    path: "/admin",
    navigate: "/admin/dashboard",
    isPrivate: true,
  },
  // Private Routes
  {
    path: "/admin/dashboard",
    component: HomePage,
    isPrivate: true,
  },
  {
    path: "/users",
    component: Users,
    isPrivate: true,
  },
  {
    path: "/users/view/:id",
    component: UserDetails,
    isPrivate: true,
  },
  {
    path: "/hobbies",
    component: Hobbies,
    isPrivate: true,
  },
  {
    path: "/hobbies/view/:id",
    component: HobbyDetails,
    isPrivate: true,
  },
  {
    path: "/hobby-category",
    component: HobbyCategory,
    isPrivate: true,
  },
  {
    path: "/questions",
    component: Questions,
    isPrivate: true,
  },
  {
    path: "/questions",
    component: Questions,
    isPrivate: true,
  },
  {
    path: "/landing-page",
    component: landingPage,
    isPrivate: true,
  },
  {
    path: "/admin/info",
    component: AdminInfo,
    isPrivate: true,
  },
  {
    path: "/admin/map",
    component: MapPage,
    isPrivate: true,
  },
  {
    path: "/purchases",
    component: Purchase,
    isPrivate: true,
  },
];

export default Routes;
