import React from "react";
import { CgClose, CgCross } from "react-icons/cg";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

const AuthLayout = ({ children, ...props }) => {
  return <>{children}</>;
};

export { AuthLayout };
