import React from "react";
import axiosInstance from "../../utils/axios";
import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from "recharts";
import { useStore } from "../../contexts/StoreProvider";

const HomePage = () => {
  const [data, setData] = React.useState({});
  const [months, setMonths] = React.useState([]);
  const { setLoading } = useStore();

  React.useEffect(() => {
    getData();
    getMonthsData()
  }, []);
  console.log(months,'months')
  const getData = async () => {
    setLoading(true);
    let response = await axiosInstance.get("/dashboard");
    setLoading(false);
    setData(response);
  };
  const getMonthsData = async () => {
    setLoading(true);
    let response = await axiosInstance.get("/users/month");
    setLoading(false);
    setMonths(response.data);
  };
  const colors = [
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
    "#" + Math.floor(Math.random() * 16777215).toString(16),
  ];


  const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${
      x + width / 2
    },${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
      x + width
    }, ${y + height}
  Z`;
  };

  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  return (
    <div>
      <h1 class="text-3xl font-semibold">Dashboard</h1>
      <div class="grid grid-cols-4 gap-5 mt-10">
        <div className="bg-white px-4 py-5 rounded-md shadow-xl">
          <h1>All Users</h1>
          <h1 className="text-5xl pt-2">
            {data?.usersCount?.toString()?.padStart(2, "0")}
          </h1>
        </div>
        <div className="bg-white px-4 py-5 rounded-md shadow-xl">
          <h1>All Hobbies</h1>
          <h1 className="text-5xl pt-2">
            {data?.hobbiesCount?.toString()?.padStart(2, "0")}
          </h1>
        </div>
        <div className="bg-white px-4 py-5 rounded-md shadow-xl">
          <h1>All Hobby Categories</h1>
          <h1 className="text-5xl pt-2">
            {data?.hobbyCategoryCount?.toString()?.padStart(2, "0")}
          </h1>
        </div>
        <div className="bg-white px-4 py-5 rounded-md shadow-xl">
          <h1>All Questions</h1>
          <h1 className="text-5xl pt-2">
            {data?.questionsCount?.toString()?.padStart(2, "0")}
          </h1>
        </div>
      </div>
      <div class="mt-10" />

      {/* Charts */}
      <BarChart
        width={800}
        height={400}
        data={months}
        margin={{
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Bar dataKey="count" fill="#8884d8" shape={<TriangleBar />}>
          {months?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

export { HomePage };
