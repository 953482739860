import { AiOutlineQuestionCircle, AiOutlineRead } from "react-icons/ai";
import { BiCategoryAlt } from "react-icons/bi";
import { FiUsers, FiUserPlus } from "react-icons/fi";
import { MdDashboard } from "react-icons/md";
import { TiMap } from "react-icons/ti";

const SidebarRoutes = [
  {
    icon: MdDashboard,
    path: "/admin/dashboard",
    name: "Dashboard",
  },
  {
    icon: FiUsers,
    path: "/users",
    name: "Users",
  },
  {
    icon: FiUserPlus,
    path: "/purchases",
    name: "Purchases",
  },
  {
    icon: AiOutlineRead,
    path: "/hobbies",
    name: "Hobbies",
  },
  {
    icon: BiCategoryAlt,
    path: "/hobby-category",
    name: "Hobby Category",
  },
  {
    icon: AiOutlineQuestionCircle,
    path: "/questions",
    name: "Questions",
  },
  {
    icon: AiOutlineQuestionCircle,
    path: "/admin/info",
    name: "Update Login Info",
  },
  {
    icon: TiMap,
    path: "/admin/map",
    name: "Map",
  },
];

export default SidebarRoutes;
