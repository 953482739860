import curveLine from "../../../assets/forth-section/curveLine.svg";
import card1 from "../../../assets/forth-section/card1.svg";
import card2 from "../../../assets/forth-section/card2.svg";
import card3 from "../../../assets/forth-section/card3.svg";
import Ai from "../../../assets/forth-section/Ai.svg";

const SectionFour = () => {
  return (
    <div className="w-full top-0 md:px-8 px-3 text-[#ffffff] text-center section4-bg">
      <div className="flex flex-col justify-center items-center px-2 max-w-[1400px] m-auto">
        <div className="text-[30px] sm:text-[42px] leading-[52px] font-bold">
          Deepen Your Spiritual{" "}
          <span className="curveLineSection">Journey Together</span>
        </div>

        <div className="mt-10 text-[20px] w-full sm:w-1/2 m-auto">
          The eyes are the windows to the soul! We encourage a profile's first
          picture to be a selfie or a close up
        </div>
        <div className="flex w-full  justify-center flex-col items-center lg:flex-row m-auto px-4">
          <img src={card1} alt="card1" className=" w-[400px] h-[400px]" />
          <img src={card2} alt="card2" className=" w-[400px] h-[400px]" />
          <img src={card3} alt="card3" className=" w-[400px] h-[400px]" />
        </div>
      </div>
      <div className="flex justify-end items-end ">
        <img
          src={Ai}
          alt="Ai"
          className="h-[200px] md:h-[400px] mb-[-320px]  sm:mb-[-250px]"
        />
      </div>
    </div>
  );
};
export default SectionFour;
