import React, { createContext, useContext, useState } from "react";

export const StoreContext = createContext();

export const useStore = () => {
  return useContext(StoreContext);
};

const StoreProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  return (
    <StoreContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
