import React, { createContext, useContext, useState } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import axiosInstance from "../utils/axios";
import { LocalStorageKeys } from "../utils/constants";
import HelperFunc from "../utils/helpers";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = React.useState(false);

  React.useEffect(() => {
    let user = localStorage.getItem(LocalStorageKeys.USER);
    let LoggedIn = localStorage.getItem(LocalStorageKeys.LOGGED_IN);
    setAuthenticated(LoggedIn == "true");
    setLoading(false);
  }, []);

  const login = async (user, setLoading) => {
    let { email, password } = user;
    let { success, data, token, message } = await axiosInstance.post(
      "/admin/login",
      user
    );
    if (success) {
      toast.success("Logged In Successful");
      localStorage.setItem(LocalStorageKeys.LOGGED_IN, true);
      localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(data));
      localStorage.setItem(LocalStorageKeys.TOKEN, token);
      setAuthenticated(true);
    } else {
      setLoading(false);
      toast.error(message);
    }
  };

  const logout = async () => {
    localStorage.clear();
    setAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
