import React from "react";
import icon1 from "../../assets/second-section/icon1.svg";
import icon2 from "../../assets/second-section/icon2.svg";
import icon3 from "../../assets/second-section/icon3.svg";
import underline from "../../assets/second-section/under-line.svg";

const SecondSection = () => {
  return (
    <div className="section-2 md:pt-[90px] lg:px-[70px] 2xl:pl-[400px] md:px-[30px] sm:pt-[0px ] pt-[0px] md:pb-[150px]  sm:pb-[20px]  pb-[20px] bg-[#587CFA]">
      <div className=" md:grid grid-cols-12 items-center  md:text-start text-center sm:text-center justify-center sm:flex flex sm:flex-col-reverse flex-col-reverse md:px-[0px] sm:px-[30px] px-[30px]">
        <div className="lg:col-span-5 md:col-span-5 col-span-6 ">
          <img
            src={require("../../assets/second-section/heart.webp")}
            alt=""
            className="2xl:h-[auto] lg:h-[400px]  sm:mb-0 mb-[50px]"
          />
        </div>

        <div className="lg:col-span-7 md:col-span-7 col-span-6 md:pr-[70px] pt-[130px]">
          <h1 className="text-white 2xl:leading-[52px] md:m-[inherit] sm:m-[auto]  m-[auto] 2xl:max-w-[500px] 2xl:text-[42px] font-[700] md:text-[30px] text-[22px] lg:leading-[35px] max-w-[360px]">
            Experience Love And Connection on a Deeper Level
          </h1>
          <img
            src={underline}
            alt="Underline"
            className="2xl:w-[290px] w-[220px]  sm-m-[auto]  m-[auto]  md:m-[inherit]"
          />
          <h1 className="text-white 2xl:text-[20px] text-[14px] 2xl:leading-[30px]  md:m-[inherit] sm:m-[auto]  m-[auto] 2xl:max-w-[630px]  2xl:leading-[30px] pt-[10px] pb-[20px] max-w-[470px]">
            Our goal with Presence is to help the world create meaningful,
            authentic connections with others who share your spiritual values
            and beliefs
          </h1>
          <div className="flex gap-5 pt-[10px] pb-[80px] md:flex-row sm:flex-row flex-col">
            <div className="flex md:flex-col sm:flex-row flex-row items-center gap-1 justify-center">
              <img src={icon1} className="w-[70px]" />
              <h1 className="text-white  text-[12px] lg:text-[16px] font-bold flex gap-1">
                Spiritual <span className="text-[#00E093]">Dating</span>{" "}
              </h1>
            </div>
            <div className="flex md:flex-col sm:flex-row flex-row items-center gap-1 justify-center">
              <img src={icon2} alt="" className="w-[70px]" />
              <h1 className="text-white text-[12px]   lg:text-[16px] font-bold flex gap-1">
                Customize <span className="text-[#FFA823]">Profile</span>{" "}
              </h1>
            </div>
            <div className="flex md:flex-col sm:flex-row flex-row items-center gap-1 justify-center">
              <img src={icon3} alt="" className="w-[70px]" />
              <h1 className="text-[#1F1F21]   text-[12px]    lg:text-[16px] font-bold flex gap-1">
                Discover <span className="text-[#FF6240]"> People</span>{" "}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
