import React from "react";
import SectionOne from "../../components/homePage/sectionOne";
import SectionTwo from "../../components/homePage/sectionTwo";
import SectionThree from "../../components/homePage/sectionThree";
import SectionFour from "../../components/homePage/sectionFour";
import SectionFive from "../../components/homePage/sectionFive";
import Footer from "../../components/homePage/footer";

const LandingPage = () => {
  return (
    <div className="right-0 left-0">
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <Footer />
    </div>
  );
};

export default LandingPage;
