import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.svg";

const Header = () => {
  return (
    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200">
      <div className="px-3 lg:px-5 lg:pl-3">
        <div className="h-[8vh] flex items-center justify-between">
          <div className="flex items-center justify-start">
            <NavLink to="/admin/dashboard" className="flex ml-10 md:mr-24">
              <img src={Logo} className="h-11 mr-3" alt="FlowBite Logo" />
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
