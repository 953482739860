import React from "react";
import Logo from "../../assets/Footer/Logo.svg";
import mapPin from "../../assets/Footer/map-pin.svg";
import call from "../../assets/Footer/call-calling.svg";
import Facebook from "../../assets/Footer/facebook.svg";
import Twitter from "../../assets/Footer/twiiter.svg";
import LinkdIn from "../../assets/Footer/linkdin.svg";
import Instagram from "../../assets/Footer/Instagram.svg";
import GooglePlay from "../../assets/Footer/Google_Play.svg";
import AppStore from "../../assets/Footer/App_store.svg";
import FooterBg from "../../assets/Footer.png";
import FooterBg1 from "../../assets/BG shape (1).png";
import FooterBgMbl from "../../assets/Footer-mbl.png";
import FooterTab from "../../assets/Footer_tab.png";
import FooterTab2 from "../../assets/Group 1000006860 (2).png";

const Footer = () => {
  return (
    <>
      <div className="relative lg:mt-[-100px] md:mt-[-120px] sm:mt-[-13px] mt-[-16px]">
        <div>
          <img
            src={FooterBg1}
            className="arrow w-full 2xl:block lg:block md:hidden sm:hidden hidden"
          />
          <img
            src={FooterBgMbl}
            className="arrow w-full sm:block md:hidden  h-full"
          />
          <img
            src={FooterTab2}
            className="arrow w-full md:block lg:hidden xl:hidden sm:hidden hidden h-full"
          />
        </div>
        <div className="absolute 2xl:top-[314px] top-[70px] w-full  md:pt-0 lg:pt-[85px] bg-[#42CCFF]">
          <div className="grid md:grid-cols-11 items-center md:mt-[-60px]">
            <div className="col-span-2"></div>
            <div className="col-span-3 text-white md:justify-start justify-center items-center md:items-start text-center md:text-start flex flex-col">
              <img
                src={Logo}
                className="md:w-[80px] md:px-[0] pb-[50px] sm:pb-0"
                alt="logo-footer"
              />
              <h1 className="text-[20px] md:text-[14px] lg:text-[17px] font-semibold leading-loose md:leading-[16px] lg:leading-relaxed">
                Be More Present Than Ever!
              </h1>
              <h1 className="text-[17px] sm:text-[16px] md:text-[12px] leading-loose">
                Stay up-to-date with Presence - Spiritual Dating{" "}
              </h1>
              <h1 className="text-[17px] sm:text-[12px] leading-loose">
                Enter your email to stay updated with new app features and your
                favorite person.
              </h1>
              <div className="contact flex gap-4 text-[16px] sm:text-[12px] md:justify-start justify-center leading-loose my-2">
                <div className="flex">
                  <img
                    src={mapPin}
                    alt="map-pin"
                    className="pr-[8px] md:pr-0 lg:pr-[8px]"
                  />
                  <h1>Blue Area USA</h1>
                </div>
                <div className="flex">
                  <img src={call} alt="call-calling" className="pr-[8px]" />
                  <h1>+1 3214 456 123</h1>
                </div>
              </div>
              <div className="social-media flex gap-4 md:justify-start justify-center  pb-5 sm:pb-0">
                <img src={Facebook} className="w-[35px]" />
                <img src={LinkdIn} className="w-[35px]" />
                <img src={Twitter} className="w-[35px]" />
                <img src={Instagram} className="w-[35px]" />
              </div>
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-3 !text-white">
              <h1 className="text-[20px] sm:text-[17px] font-semibold leading-loose md:text-start text-center sm:pb-0 pb-2">
                Subscribe Our News Letter
              </h1>

              <div className="border-b-[5px] border-t-[1px]   border-l-[0.5px] border-r-[5px] rounded-md border-white flex justify-between !text-white mb-[35px] md:mx-[0px] mx-[30px]">
                <input
                  type="text"
                  className="placeholder-white text-white border-none outline-none bg-transparent text-[12px] m-[10px] pl-[5px]"
                  placeholder="E.g. ziggy@space.com"
                />
                <button className="text-white bg-[#FD5530] py-[1px] px-[14px] text-[13px] rounded-md m-[5px]">
                  Subscribe
                </button>
              </div>
              {/* <div className="bg-[white] py-[1px] "></div> */}
              <h1 className="text-[20px] sm:text-[17px] font-semibold md:text-start text-center leading-loose">
                Download and Signup Now!{" "}
              </h1>
              <h1 className="text-[18px] sm:text-[12px] md:text-start text-center leading-loose">
                Book time with your favorite person and schedule performances of
                your most desired person with just a few clicks.
              </h1>
              <div className="buttons flex mt-[50px] sm:mt-[10px] gap-[5px] md:justify-start justify-center items-center md:items-start">
                <button>
                  <img src={GooglePlay} className="w-[125px] h-[38px]" />
                </button>
                <button>
                  <img src={AppStore} className="w-[125px] h-[38px]" />
                </button>
              </div>
            </div>

            <div className="col-span-2"></div>
          </div>
          <div className="text-white sm:text-[12px] text-[16px] flex md:flex-row flex-col justify-around items-center text-center 2xl:pt-[60px] sm:pt-[20px] pt-[50px] ">
            <h1 className="sm:pb-0 pb-[10px]">
              © Copyright 2023 Presence - Spiritual Dating
            </h1>

            <h1 className="flex gap-[20px]">
              Privacy Policy <span>|</span> <span>Terms and Conditions</span>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
