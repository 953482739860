import React, { useState } from "react";
import SectionOneImg from "../../assets/01_Section.png";
import SectionOneImgmbl from "../../assets/01_Section-mbl.png";
import HeroSection from "./HeroSection";
import SecondSection from "./SecondSection";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import Footer from "./Footer";
import SectionFive from "./SectionFive";

function LandingPage() {
  const title1 = `What makes a dating app "spiritual"?`;
  const title2 = "How do I purchase ZAH?";
  const title3 = "Where is the white paper?";
  const title4 = "Is the liquidity pool locked?";
  const details1 =
    "1You can purchase ZAH through Pancakeswap or through our website click “Buy ZAH” on the homepage. Please click “Watch Video” on homepage for more clarification";
  const details2 =
    "2You can purchase ZAH through Pancakeswap or through our website click “Buy ZAH” on the homepage. Please click “Watch Video” on homepage for more clarification";
  const details3 =
    "3You can purchase ZAH through Pancakeswap or through our website click “Buy ZAH” on the homepage. Please click “Watch Video” on homepage for more clarification";
  const details4 =
    "4You can purchase ZAH through Pancakeswap or through our website click “Buy ZAH” on the homepage. Please click “Watch Video” on homepage for more clarification";

  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  const [accordionState, setAccordionState] = useState();
  return (
    <div className="App">
      <div className="section-1 first-section relative">
        <div className="">
          <img
            src={SectionOneImg}
            className="arrow w-full md:block hidden"
            alt="bg"
          />
          <img
            src={SectionOneImgmbl}
            className="arrow w-full md:hidden block"
            alt="bg"
          />
        </div>
        <HeroSection />
      </div>
      <div className="relative  md:top-[-100px] top-[-85px] z-[-1]">
        <SecondSection />
      </div>
      <SectionThree />
      <SectionFour />
      <SectionFive />

      <Footer />
    </div>
  );
}
export default LandingPage;
