import Logo from "../../../assets/Footer/Logo.svg";
import Location from "../../../assets/Footer/map-pin.svg";
import Calling from "../../../assets/Footer/call-calling.svg";
import Facebook from "../../../assets/Footer/facebook.svg";
import linkdin from "../../../assets/Footer/linkdin.svg";
import Instagram from "../../../assets/Footer/Instagram.svg";
import twitter from "../../../assets/Footer/twiiter.svg";
import AppStore from "../../../assets/Footer/appstore.svg";
import GooglePlay from "../../../assets/Footer/GooglePlay.svg";

const Footer = () => {
  return (
    <div className="w-full top-0  md:px-8 px-3 footer text-white text-center sm:text-left">
      <div className="flex flex-col lg:flex-row items-center w-full sm:w-[80%] m-auto py-3 sm:py-10 gap-10 sm:gap-[10rem] px-3 max-w-[1400px] m-auto">
        <div className="flex flex-col items-center sm:items-start gap-3">
          <img src={Logo} alt="Logo" width={90} height={90} />
          <div className="text-[20px] sm:text-[24px] leading-[34px] font-bold">
            Be More Present Than Ever!
          </div>
          <div className="text-[18px] leading-[28px] w-[70%]">
            Stay up-to-date with Presence - Spiritual Dating! Find us below or
            subscribe on the right to stay updated with new app features
          </div>
          <div className="flex gap-3">
            <div className="flex gap-3">
              <img src={Location} alt="Location" />
              <div className="text-[18px]">Los Angeles, California</div>
            </div>
            <div className="flex gap-3">
              <img src={Calling} alt="Calling" />
              <div className="text-[18px]">+1 800 395 5953</div>
            </div>
          </div>
          <div className="flex gap-5">
            <img src={Facebook} alt="Facebook" />
            <img src={linkdin} alt="linkdin" />
            <img src={twitter} alt="twitter" />
            <img src={Instagram} alt="Instagram" />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-[20px] sm:text-[24px] font-bold">
            <div className="py-3">Subscribe To Our Newsletter</div>
            <div className="border-b-[5px] border-t-[1px]   border-l-[0.5px] border-r-[5px] rounded-md border-white flex justify-between !text-white mb-2 sm:mb-[35px] mx-0 min-w-[300px]">
              <input
                type="text"
                className="placeholder-white text-white border-none outline-none bg-transparent py-4 sm:py-2 text-[17px] font-normal m-0 sm:m-[10px] pl-0 sm:pl-[10px]"
                placeholder="E.g. akash@truesoul.com"
              />
              <button className="text-white bg-[#FD5530] py-[1px] px-2 sm:px-[14px] text-[13px] rounded-md mx-3 my-2 sm:m-[5px]">
                Subscribe
              </button>
            </div>
          </div>

          <div className="text-[20px] sm:text-[25px] font-bold mt-3">
            Download and Sign Up Now!
          </div>
          <div className="text-[18px] break-words">
            Meet a present soul and a unique person
            with just a few taps
          </div>
          <div className="flex flex-row justify-center sm:justify-start gap-2">
            <img
              src={GooglePlay}
              alt="GooglePlay"
              className="w-[145px] h-[48px]"
            />
            <img src={AppStore} alt="AppStore" className="w-[145px] h-[48px]" />
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse sm:flex-row items-center justify-between text-sm sm:text-[1.1rem] sm:w-[80%] m-auto py-5 sm:py-10 border-t border-white border-opacity-20	max-w-[1400px] m-auto">
        <div>© Copyright 2023 Triple Realms LLC</div>
        <div className="flex sm:flex-row text-sm sm:text-[1.1rem] gap-2">
          <div>Privacy Policy</div>
          <div className="">|</div>
          <div className="">Terms and Conditions</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
