import React from "react";
import Arrow from "../../assets/third-section/Arrow (1).svg";
import Num1 from "../../assets/third-section/Num1.svg";
import Num2 from "../../assets/third-section/Num2.svg";
import Num3 from "../../assets/third-section/Num3.svg";
import Num4 from "../../assets/third-section/Num4.svg";
import SectionThreeImg from "../../assets/03_Section.png";
import SectionThreeImgMbl from "../../assets/03_Section-mbl.png";
const SectionThree = () => {
  return (
    <div className="section-3 top-[-140px] md:mb-[0px] mb-[-183px] 2xl:top-[-290px] md:top-[-260px] relative">
      <div className=" ">
        <div className="">
          <img
            src={SectionThreeImg}
            className="arrow w-full  2xl:h-[849px] md:h-[750px] md:block sm:hidden hidden"
            alt="background"
          />
          <img
            src={SectionThreeImgMbl}
            className=" md:hidden sm:block block w-full h-full"
            alt="background"
          />
        </div>
        <div className="absolute 2xl:top-[150px] md:top-[159px] top-[200px] w-full">
          <div className="grid md:grid-cols-8 grid-cols-1">
            <div className="md:col-span-2 flex-col justify-end  md:flex sm:hidden hidden">
              <img
                className="girl absolute bottom-0 xl:w-[250px] lg:w-[225px] md:w-[150px] md:ml-[20px] xl:mb-[50px] md:mb-[3px] lg:mb-[20px]  2xl:mb-[509px]"
                src={require("../../assets/third-section/Girl (1).webp")}
                alt="Girl"
              />
            </div>
            <div className="md:col-span-4 2xl:pt-[29px] md:pt-[0px] pt-[0px] md:px-[0px] px-[20px]">
              <div className="content">
                <h1 className="text-white md:text-[28px] lg:text-[30px] text-[25px] md:text-left text-center font-semibold mt-[-100px] sm:mt-[30px] lg:mt-[5px]">
                  How Does
                  <span> Presence Work? </span>
                </h1>
                <img
                  src={require("../../assets/first-section/white-underline.webp")}
                  alt=""
                  className="max-w-[260px] md:w-[250px] w-[220px] 2xl:max-w-[440px]  sm-m-[auto]  m-[auto]  md:m-[inherit] lg:ml-[165px]"
                />

                <div className="flex relative">
                  <h1 className="text-white md:max-w-[400px] lg:mb-[20px] md:text-left text-center md:py-[0px] py-[5px]">
                    Presence - Spiritual Dating an app to connect souls, not
                    just people. Where you can meet your twin flame and zodiac
                    match.
                  </h1>
                  <img
                    src={Arrow}
                    className="arrow lg:w-[180px] md:w-[100px] absolute lg:top-[-85px] md:top-[-100px] lg:left-[400px] md:left-[200px] md:block hidden"
                  />
                </div>
                <div className="flex flex-col gap-[30px]">
                  <div className="cardsRow-1 grid md:grid-cols-2 gap-[20px]">
                    <div>
                      <div className="bg-[#FFF5BE] py-[12px]  pl-[10px] rounded border-[1px] border-black inner-cards">
                        <img
                          src={Num1}
                          className="sm:w-[50px] w-[25px] md:w-[20px] lg:w-[30px]"
                        />
                        <h1 className="font-semibold md:font-bold sm:font-semibold py-[5px] lg:py-[10px]">
                          Download App
                        </h1>
                        <h1 className="text-[12px] lg:text-[14px] text-[#474A57]">
                          Thousands of singles find deeper love through our app
                          each month.
                        </h1>
                      </div>
                    </div>

                    <div className="bg-[#D5F5EA] py-[12px] pl-[10px] rounded border-[1px] border-black  inner-cards">
                      <img
                        src={Num2}
                        className="sm:w-[50px] w-[25px] md:w-[20px] lg:w-[30px]"
                      />
                      <h1 className="font-semibold md:font-bold sm:font-semibold py-[5px] lg:py-[10px]">
                        Login
                      </h1>
                      <h1 className="text-[12px] lg:text-[14px] text-[#474A57]">
                        Use our never before seen no sign-up login for a hassle
                        free start.
                      </h1>
                    </div>
                  </div>
                  <div className="cardsRow-2 grid md:grid-cols-2 gap-[20px]">
                    <div className="bg-[#D1FAFF] py-[12px] pl-[10px] rounded border-[1px] border-black  inner-cards">
                      <img
                        src={Num3}
                        className="sm:w-[50px] w-[25px] md:w-[20px] lg:w-[30px]"
                      />
                      <h1 className="font-semibold md:font-bold sm:font-semibold py-[5px] lg:py-[10px]">
                        Profile Setup
                      </h1>
                      <h1 className="text-[12px] lg:text-[14px] text-[#474A57]">
                        Enjoy setting up a profile that truly
                        <br /> represents your spiritual journey.
                      </h1>
                    </div>
                    <div className="bg-[#FFDEE7] py-[12px] pl-[10px] rounded border-[1px] border-black  inner-cards">
                      <img
                        src={Num4}
                        className="sm:w-[50px] w-[25px] md:w-[20px] lg:w-[30px]"
                      />
                      <h1 className="font-semibold md:font-bold sm:font-semibold py-[5px] lg:py-[10px]">
                        You're in!
                      </h1>
                      <h1 className="text-[12px] lg:text-[14px] text-[#474A57]">
                        Create meaningful connections with like-
                        <br />
                        minded individuals and like-hearted souls.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:col-span-2 md:block sm:hidden hidden md:ml-[28px] xl:mt-[108px] lg:mt-[196px] md:mt-[297px]">
              <img
                className="mobile"
                src={require("../../assets/third-section/mobile_heart.webp")}
                alt="mbl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
