import React from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
import MapComponent from "./MapComponent";
import {
  MAPS_PER_PAGE_DATA_LIMIT,
  MAx_MAPS_PER_PAGE_DATA_LIMIT,
} from "../../utils/constants";
import { useStore } from "../../contexts/StoreProvider";

const MapPage = () => {
  const [users, setUsers] = React.useState([]);
  const [showProfiles, setShowProfiles] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const { setLoading } = useStore();

  const getUsersData = async (page, all = false) => {
    setLoading(true);
    let response = await axiosInstance.get(`/users`, {
      params: {
        page: page || currentPage,
        limit: all ? MAx_MAPS_PER_PAGE_DATA_LIMIT : MAPS_PER_PAGE_DATA_LIMIT,
      },
    });
    setLoading(false);
    if (response.success) {
      setUsers(response.data);
      !all && setCurrentPage(Number(response?.currentPage));
      !all && setTotalPages(Number(response?.totalPages));
    } else {
      toast.error(response?.message);
    }
  };

  React.useLayoutEffect(() => {
    getUsersData();
  }, []);

  const toggleSwitches = [
    {
      name: "showProfiles",
      label: "Show Profiles",
      value: showProfiles,
      onchange: (e) => setShowProfiles(e.target.checked),
    },
    {
      name: "showAllUsers",
      label: "Show All Users",
      value: showAll,
      onchange: (e) => {
        const checked = e.target.checked;
        getUsersData(!checked ? currentPage : 1, e.target.checked);
        setShowAll(e.target.checked);
      },
    },
  ];

  return (
    <div>
      <div className="">
        <div>
          <h1 class="text-4xl font-semibold">All Users Location</h1>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-left justify-between flex-col gap-2">
           <div className="flex">
           {toggleSwitches?.map(({ name, label, value, onchange }) => {
              return (
                <label className="flex items-center cursor-pointer ml-2" key={name}>
                  <input
                    type="checkbox"
                    defaultValue=""
                    className="sr-only peer"
                    value={value}
                    onChange={onchange}
                  />
                  <div className="relative w-11 h-6 bg-gray-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                  <span className="ms- ml-2 text-sm font-medium text-gray-900">
                    {label}
                  </span>
                </label>
              );
            })}
           </div>
          </div>

          {/* pagination ================================================================= */}
          {totalPages > 1 && (
            <div className="flex items-center justify-center flex-col">
              <nav aria-label="Page navigation example">
                <ul class="inline-flex -space-x-px">
                  <li>
                    <button
                      class={`px-3 py-2 ml-0 leading-tight bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 text-gray-700 ${
                        (currentPage === 1 || showAll) &&
                        "!bg-gray-100 hover:bg-gray-300"
                      }`}
                      disabled={currentPage === 1 || showAll}
                      onClick={() => {
                        getUsersData(parseInt(currentPage) - 1);
                      }}
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages })?.map((_, i) => {
                    return (
                      <li key={i}>
                        <button
                          class={`px-3 py-2 leading-tight bg-white border border-gray-300 hover:bg-gray-100 text-gray-700 ${
                            (currentPage === i + 1 || showAll) &&
                            "!bg-gray-300 hover:bg-gray-300"
                          }`}
                          onClick={() => {
                            getUsersData(i + 1);
                          }}
                          disabled={currentPage === i + 1 || showAll}
                        >
                          {i + 1}
                        </button>
                      </li>
                    );
                  })}
                  <li>
                    <button
                      class={`px-3 py-2 ml-0 leading-tight bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 text-gray-700 ${
                        (currentPage === totalPages || showAll) &&
                        "!bg-gray-100 hover:bg-gray-300"
                      }`}
                      disabled={currentPage === totalPages || showAll}
                      onClick={(e) => {
                        if (currentPage !== totalPages)
                          getUsersData(parseInt(currentPage) + 1);
                      }}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
              {/* <span class="text-sm text-gray-700 mt-1">
              Showing{" "}
              <span class="font-semibold text-gray-700">
                {(parseInt(currentPage) - 1) * MAPS_PER_PAGE_DATA_LIMIT}
              </span>{" "}
              to{" "}
              <span class="font-semibold text-gray-700">
                {parseInt(currentPage) * MAPS_PER_PAGE_DATA_LIMIT}
              </span>{" "}
              of{" "}
              <span class="font-semibold text-gray-700">
                {parseInt(totalPages) * MAPS_PER_PAGE_DATA_LIMIT}
              </span>{" "}
              Entries
            </span> */}
            </div>
          )}
          {/* Pagination ========> */}
        </div>
      </div>
      <div className="mt-5">
        <MapComponent usersData={users} showProfiles={showProfiles} />
      </div>
    </div>
  );
};

export { MapPage };
