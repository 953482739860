import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { BiReset } from "react-icons/bi";
import { GrView } from "react-icons/gr";
import { HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useStore } from "../../contexts/StoreProvider";
import axiosInstance from "../../utils/axios";
import { PER_PAGE_DATA_LIMIT } from "../../utils/constants";

let tableRows = [
  {
    name: "Name",
    key: "name",
  },
  { name: "Email", key: "email" },
];

const Users = () => {
  const [data, setData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [userId, setUserId] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setLoading } = useStore();

  const navigate = useNavigate();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(item) {
    setItemToDelete(item._id);
    setIsOpen(true);
  }

  function closeMatchesModal() {
    setIsModalOpen(false);
  }

  function openMatchesModal(item) {
    setUserId(item._id);
    setIsModalOpen(true);
  }

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async (page) => {
    setLoading(true);
    let response = await axiosInstance.get("/users", {
      params: {
        page: page || currentPage,
        limit: PER_PAGE_DATA_LIMIT,
      },
    });
    setLoading(false);
    if (response?.success) {
      setData(response?.data);
      setCurrentPage(response?.currentPage);
      setTotalPages(response?.totalPages);
    } else {
      toast.error(response?.message);
    }
  };

  const deleteSelectedItem = async () => {
    setLoading(true);
    closeModal();
    let response = await axiosInstance.delete("/users/" + itemToDelete);
    if (response.success) {
      getData(currentPage);
      toast.success("User Deleted Successful!");
    } else {
      toast.error("Unable to delete");
    }
  };

  const removeMatches = async () => {
    setLoading(true);
    closeMatchesModal();
    let response = await axiosInstance.put("/users/remove_matches/", {
        user_id: userId,
    });
    if (response.success) {
      getData(currentPage);
      toast.success("User Matches removed Successfully!");
    } else {
      toast.error("Unable to remove Matches");
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 class="text-3xl font-semibold">Users</h1>
        {/* <NavLink
          to={"create"}
          type="button"
          className="flex items-center justify-center text-on-primary rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium hover:bg-primary-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mt-1"
        >
          <HiUserAdd className="mr-2 text-[18px]" />
          Add User
        </NavLink> */}
      </div>
      {/* Table */}
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-200 border-b-2">
            <tr>
              {tableRows?.map((_) => {
                return (
                  <th scope="col" class="px-6 py-3">
                    {_?.name ?? "N/A"}
                  </th>
                );
              })}
              <th scope="col" class="px-6 py-3">
                <span class="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => {
              return (
                <tr key={item} class="bg-white border-b hover:bg-gray-50">
                  {tableRows?.map((_) => {
                    return <td class="px-6 py-3">{item[_?.key]}</td>;
                  })}
                  <td class="px-6 py-4 text-right">
                    <button
                      onClick={() => navigate(`view/${item._id}`)}
                      className="text-[20px] text-dark"
                    >
                      <GrView />
                    </button>
                    <button
                      className="text-[20px] text-dark ml-2"
                      onClick={() => openModal(item)}
                    >
                      <HiTrash />
                    </button>
                    <button
                      onClick={() => openMatchesModal(item)}
                      className="text-[20px] text-dark"
                    >
                      <BiReset />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <br />
      {totalPages > 1 && (
        <div className="flex items-center justify-center flex-col">
          <nav aria-label="Page navigation example" className="mt-3">
            <ul class="inline-flex -space-x-px">
              <li>
                <button
                  class={`px-3 py-2 ml-0 leading-tight bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 text-gray-700 ${
                    currentPage === 1 && "!bg-gray-300 hover:bg-gray-300"
                  }`}
                  onClick={() => {
                    getData(parseInt(currentPage) - 1);
                  }}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {Array.from({ length: totalPages })?.map((_, i) => {
                return (
                  <li key={i}>
                    <button
                      class={`px-3 py-2 leading-tight bg-white border border-gray-300 hover:bg-gray-100 text-gray-700 ${
                        currentPage === i + 1 &&
                        "!bg-gray-300 hover:bg-gray-300"
                      }`}
                      onClick={() => {
                        getData(i + 1);
                      }}
                      disabled={currentPage === i + 1}
                    >
                      {i + 1}
                    </button>
                  </li>
                );
              })}
              <li>
                <button
                  class={`px-3 py-2 ml-0 leading-tight bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 text-gray-700 ${
                    currentPage === totalPages &&
                    "!bg-gray-300 hover:bg-gray-300"
                  }`}
                  disabled={currentPage === totalPages}
                  onClick={() => {
                    getData(parseInt(currentPage) + 1);
                  }}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
          <span class="text-sm text-gray-700 mt-1">
            Showing{" "}
            <span class="font-semibold text-gray-700">
              {(parseInt(currentPage) - 1) * PER_PAGE_DATA_LIMIT}
            </span>{" "}
            to{" "}
            <span class="font-semibold text-gray-700">
              {parseInt(currentPage) * PER_PAGE_DATA_LIMIT}
            </span>{" "}
            of{" "}
            <span class="font-semibold text-gray-700">
              {parseInt(totalPages) * PER_PAGE_DATA_LIMIT}
            </span>{" "}
            Entries
          </span>
        </div>
      )}
      {/* Pagination ========> */}

      {/* Are you sure you want to delete item */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div class="relative w-full h-full max-w-md md:h-auto">
                  <div class="relative  rounded-lg shadow bg-gray-200">
                    <button
                      type="button"
                      class="absolute top-3 right-2.5 text-gray-500 bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                      onClick={closeModal}
                    >
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                    <div class="p-6 text-center">
                      <svg
                        aria-hidden="true"
                        class="mx-auto mb-4 w-14 h-14 text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                      <h3 class="mb-5 text-lg font-normal text-gray-500">
                        Are you sure you want to delete this product?
                      </h3>
                      <button
                        onClick={deleteSelectedItem}
                        type="button"
                        class="text-white bg-red-600 hover:bg-red-800 focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                      >
                        Yes, I'm sure
                      </button>
                      <button
                        onClick={closeModal}
                        type="button"
                        class="rounded-lg border text-sm font-medium px-5 py-2.5 focus:z-10 bg-gray-100 text-gray-500 border-gray-150 "
                      >
                        No, cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>


      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeMatchesModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div class="relative w-full h-full max-w-md md:h-auto">
                  <div class="relative  rounded-lg shadow bg-gray-200">
                    <button
                      type="button"
                      class="absolute top-3 right-2.5 text-gray-500 bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                      onClick={closeMatchesModal}
                    >
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span class="sr-only">Close modal</span>
                    </button>
                    <div class="p-6 text-center">
                      <svg
                        aria-hidden="true"
                        class="mx-auto mb-4 w-14 h-14 text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                      <h3 class="mb-5 text-lg font-normal text-gray-500">
                        Are you sure you want to remove all the matches?
                      </h3>
                      <button
                        onClick={removeMatches}
                        type="button"
                        class="text-white bg-red-600 hover:bg-red-800 focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                      >
                        Yes, I'm sure
                      </button>
                      <button
                        onClick={closeMatchesModal}
                        type="button"
                        class="rounded-lg border text-sm font-medium px-5 py-2.5 focus:z-10 bg-gray-100 text-gray-500 border-gray-150 "
                      >
                        No, cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export { Users };
